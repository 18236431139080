<template>
  <v-container fluid tag="section">
    <v-layout align-center justify-center>
      <v-card width="400" class="mx-auto mt-5">
        <v-card-text class="pb-0">
          <v-spacer/>
          <h1 class="text-h3 text-center">登入</h1>
          <v-spacer/>
        </v-card-text>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="account"
              prepend-icon="mdi-account-circle"
              @keyup.enter="loginAccount"
            />
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="loginAccount"
            />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="info" class="text-h4" @click="loginAccount">登入</v-btn>
        </v-card-actions>
        <v-card-text class="text-center pb-0">version: {{ version }}</v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { version } from "../../../../package.json";
import { mapState, mapMutations, mapActions } from "vuex";
import { UserRole } from '@/definition'
export default {
  name: "login",
  data() {
    return {
      showPassword: false,
      account: "",
      password: "",
      UserRole: UserRole,
      version:version,
    };
  },
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapActions(["sumbitToken"]),
    ...mapMutations(['setTitle', "SET_DRAWER"]),
    loginAccount() {
      if (this.account.length > 0 && this.password.length > 0) {
        let url = process.env.VUE_APP_SERVER_URL + "/backend/api-token-auth/";
        let currentObj = this;
        this.axios
          .post(url, {
            username: this.account,
            password: this.password
          })
          .then(function(response) {
            let info = JSON.parse(JSON.stringify(response.data));
            if ("token" in info) {
              currentObj.sumbitToken({
                username: info['username'],
                token: info["token"],
                role: info["role"],
                avatar: info["avatar"],
                max_urban_renewal: info["max_urban_renewal"],
                // expiredTime: info["expiredTime"],
                company: info["company"],
              });
              currentObj.toHome(info["role"]);
            } else {
              alert("登入失敗 請檢查帳號密碼");
            }
          })
          .catch(function(error) {
            console.log(error);
            if (error.response) {
              let info = JSON.parse(JSON.stringify(error.response.data));
              if ("non_field_errors" in info) {
                alert("登入失敗 請檢查帳號密碼");
              }
            } else {
              alert("網路狀況 請稍後再試");
            }
          });
      } else {
        alert("請輸入正確資料");
      }
    },
    toHome(role) {
      let url = ''
      if(role === UserRole.Admin){
        url = 'UserMgr'
      } else {
        url = 'Index'
      }
      this.$router.push({
        name: url,
        params: {
            // item: item
        }
      });
    }
  },
  mounted(){
    this.setTitle(this.$i18n.t('title.Index'));
    this.$store.commit('SET_DRAWER', false)
  }
};
</script>

<style lang="sass" scoped>
.container
  height: 100%
.layout
  height: 100%
</style>